// 大数据研判接口
import request from '../request.js';
// 获取有害信息数据数量
function getHarmfulInformationNum() {
    return request({
        url: "module-judgment/harmful/auditInfoDataCount",
        method: 'get',
    })
}
// 获取有害信息数据列表ID/ID匹配获取数据
function getHarmfulInformationListId(data) {
    return request({
        url: "module-judgment/harmful/obtAudit",
        method: 'post',
        data
    })
}
// 获取有害信息数据列表
function getHarmfulInformationList(data) {
    return request({
        url: "module-judgment/harmful/getData",
        method: 'post',
        data
    })
}
// 获取错误表述错误类型选项
function getMisrepresentationSelect() {
    return request({
        url: "module-judgment/misrepr/getPlanMisres",
        method: 'get',
    })
}
// 获取错误表述数据数量
function getMisrepresentationNum() {
    return request({
        url: "module-judgment/misrepr/getMisreprDataCount",
        method: 'get',
    })
}
// 获取错误表述数据列表
function getMisrepresentationList(data) {
    return request({
        url: "module-judgment/misrepr/getMisreDataVos",
        method: 'post',
        data
    })
}
// 错误表述提交
function submitMisrepresentation(data) {
    return request({
        url: "module-judgment/misrepr/subMisreData",
        method: 'post',
        data
    })
}
// 有害信息提交
function submitHarmfulInformation(data) {
    return request({
        url: "module-judgment/harmful/subAudit",
        method: 'post',
        data
    })
}
// 获取异常状态数据数量
function getAbnormalStateNum() {
    return request({
        url: "module-judgment/anomaly/auditInfoDataCount",
        method: 'get',
    })
}
// 获取异常状态数据
function getAbnormalState(data) {
    return request({
        url: "module-judgment/anomaly/obtAudit",
        method: 'post',
        data
    })
}
// 异常状态数据提交
function submitExceptionFilter(data) {
    return request({
        url: "module-judgment/anomaly/subAudit",
        method: 'post',
        data
    })
}
// 异常状态添加机构
function addExceptionFilterOrg(data) {
    return request({
        url: "module-auth/orgInfo/queryPage",
        method: 'post',
        data
    })
}
// 查流水 || 处置记录
function info(data) {
    return request({
        url:'module-judgment/judgmentRecord/info',
        method:"post",
        data
    })
}
export default {
    getHarmfulInformationNum,
    getHarmfulInformationListId,
    getHarmfulInformationList,
    getMisrepresentationNum,
    getMisrepresentationList,
    submitHarmfulInformation,
    getMisrepresentationSelect,
    submitMisrepresentation,
    getAbnormalStateNum,
    getAbnormalState,
    submitExceptionFilter,
    addExceptionFilterOrg,
    info
}